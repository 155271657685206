export default {
    // Endpoints
    loginEndpoint: '/admin/auth/login',
    moduleEndpoint: '/admin/auth/clientmodules',
    logincompanyEndpoint: '/admin/auth/logincompany',
    
    registerEndpoint: '/jwt/register',
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',
  
    getUsers: '/admin/auth/f/users',
    getUsersByRole: '/admin/auth/f/usersbyrole',
    addUser: '/admin/auth/createuser',
  
    getCategories: '/lms/admin/course/category',
    addCategory: '/lms/admin/course/category',
  
    getCourses: '/lms/admin/course/getallcourses',
    getCourse: '/lms/admin/course/getcoursedetail',
    addCourse: '/lms/admin/course/create',
    updateCourse: '/lms/admin/course/updateCourse',
  
  
    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: '',
  
    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageClientAuthTokenKeyName: 'clientAuthToken',
    storageClientLogoKeyName: 'clientLogo',
    
    storageRefreshTokenKeyName: 'refreshToken',
  }
  